import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import RetailCasestudyList from "src/component/list/retailCasestudyList"

export default function Retail() {
    return (
        <>
        <Industries>
          <div className="retail inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_retail.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_retail-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>卸売/小売</p>
                                  <span>RETAIL</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                    販売データを活用した需要予測や顧客分析で、在庫最適化と売上向上を支援します。
                                    <br />
                                    多様なデータを組み合わせるマルチモーダルデータ活用により、
                                    <br />
                                    顧客行動を深く理解し、高精度の予測を実現。
                                
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIが変える<br />卸売・小売のデジタル戦略</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>リアルタイム在庫管理の導入</h3>
                                 <p>
                                    店舗・倉庫の在庫をリアルタイムで可視化し、欠品を防止。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>EC・店舗の統合データ基盤の構築</h3>
                                 <p>
                                   オムニチャネル戦略を支え、最適な販売機会を提供。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>顧客データ分析とパーソナライズ販売</h3>
                                 <p>
                                    AIが購買履歴を分析し、個別最適な提案を実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>物流・配送ネットワークの最適化</h3>
                                 <p>
                                    拠点配置や輸送計画をAIで最適化し、配送コストを削減。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>価格最適化の実施</h3>
                                 <p>
                                    競合・市場動向を分析し、ダイナミックプライシングを導入。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <RetailCasestudyList />
                    </div>
                </section>
                </div>
            </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="RETAIL"
            description=""
            pathname=""
        />
    )
}